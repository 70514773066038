<script setup>
const props = defineProps(["brandAccess", "email", "collaboratorId"]);
const { data, isLoading } = useCurrentUserBrandsQuery();

const modalStore = useModalStore();
</script>

<template>
  <DialogHeader>
    <DialogTitle>Update user access</DialogTitle>
  </DialogHeader>
  <div v-if="isLoading">loading</div>
  <div v-else>
    <LazyFormsUpdateUserAccess
      :collaboratorId="collaboratorId"
      :brands="data.brands"
      :brand-access="brandAccess"
      @success="modalStore.closeModal"
    />
  </div>
</template>
