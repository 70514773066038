import { useMutation } from "@tanstack/vue-query";

const deleteCollaborator = async ({ collaboratorId }) => {
  return await fetchWithAuth(
    `/api/currentUser/collaborators/${collaboratorId}`,
    {
      method: "DELETE",
    }
  );
};

export const useDeleteCollaboratorMutation = () => {
  return useMutation({
    mutationFn: deleteCollaborator,
  });
};
