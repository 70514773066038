<script setup>
const { data, isLoading } = useCurrentUserBrandsQuery();

const modalStore = useModalStore();
</script>

<template>
  <DialogHeader>
    <DialogTitle>Invite user</DialogTitle>
  </DialogHeader>
  <div v-if="isLoading">loading</div>
  <div v-else>
    <LazyFormsInviteUser
      :brands="data.brands"
      @success="modalStore.closeModal"
    />
  </div>
</template>
