<script setup>
import { useQueryClient } from "@tanstack/vue-query";
import { useNotification } from "@kyvg/vue3-notification";

const modalStore = useModalStore();

const { notify } = useNotification();

const props = defineProps({
  collaboratorId: { type: String, required: true },
  name: { type: String, required: false },
  email: { type: String, required: true },
});

const { mutate: deleteCollaborator } = useDeleteCollaboratorMutation();

const isSubmitting = ref(false);

const queryClient = useQueryClient();

const onSubmit = () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;

  deleteCollaborator(
    { collaboratorId: props.collaboratorId },
    {
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["collaborators"]);
        console.log(data);
        notify({
          type: "success",
          text: `No longer sharing brands with ${data.name}`,
        });
        modalStore.closeModal();
      },
      onError: async (data) => {
        notify({ type: "error", text: "Something went wrong" });
      },
    },
  );
};
</script>

<template>
  <DialogHeader>
    <DialogTitle>Stop sharing brands</DialogTitle>
  </DialogHeader>

  <Text class="mb-2"
    >Are you sure you would like to stop sharing your brands with
    {{ email }}?</Text
  >

  <Button @click="onSubmit" :loading="isSubmitting" variant="destructive"
    >Stop sharing</Button
  >
  <Button variant="secondary-outlined" @click="modalStore.closeModal"
    >Cancel</Button
  >
</template>
